import * as Yup from "yup";

export const ADD_POST_VALIDATION_SCHEMA = Yup.object({
  title: Yup.string()
    .required("שדה זה הוא חובה")
    .min(4, "כותרת חייבת להכיל לפחות 4 תווים")
    .max(40, "כותרת יכולה להכיל לכל היותר 40 תווים"),
  location: Yup.string()
    .required("שדה זה הוא חובה")
    .matches(/^[a-zA-Zא-ת\s]*$/, "מיקום יכול להכיל רק אותיות ורווחים"),
  phoneNumber: Yup.string()
    .required("שדה זה הוא חובה")
    .matches(
      /^(05\d{8})$/,
      "טלפון יכול להתחיל רק ב-05 ולהיות בעל 10 ספרות בדיוק"
    ),
  description: Yup.string()
    .required("שדה זה הוא חובה")
    .min(10, "תיאור חייב להכיל לפחות 10 תווים")
    .max(400, "תיאור יכול להכיל לכל היותר 400 תווים"),
  status: Yup.string().required("שדה זה הוא חובה"),
  category: Yup.string().required("שדה זה הוא חובה"),
});
