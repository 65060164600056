import { Box, Typography } from "@mui/material";
import styled from "@mui/system/styled";

const StyledTypography = styled(Typography)({
  color: "#fff",
  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
  fontFamily: "'Comic Sans MS', sans-serif",
});

interface EmptyStateProps {
  title: string;
  subtitle: string;
  image: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
  image,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: "20%",
      }}
    >
      <StyledTypography variant="h4" gutterBottom>
        {title}
      </StyledTypography>
      <StyledTypography variant="h6">{subtitle}</StyledTypography>
    </Box>
  );
};
