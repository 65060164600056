import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "@mui/material";
import { useGetUserQuery, useLogoutMutation } from "../../api/user.api";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const settings = [{ label: "הפוסטים שלי", path: "/my-posts" }];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { data: user } = useGetUserQuery();
  const [logout] = useLogoutMutation();
  const isLoggedIn = !isEmpty(user);

  const avatarProps = {
    alt: "None",
    src: isLoggedIn ? user.avatar : "",
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      sx={{ width: "100%", backgroundColor: "transparent", padding: "4px" }}
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="/images/logo.png"
            alt="logo"
            height="70"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {isLoggedIn ? (
                <div>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar {...avatarProps} />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary">
                    {user.displayName}
                  </Typography>
                </div>
              ) : (
                <Link onClick={() => navigate("/signin")}>
                  <IconButton sx={{ p: 0 }}>
                    <Avatar {...avatarProps} />
                  </IconButton>
                  <span style={{ display: "block" }}>להתחברות</span>
                </Link>
              )}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isLoggedIn &&
                settings.map((setting) => (
                  <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                    <Link onClick={() => navigate(setting.path)}>
                      {setting.label}
                    </Link>
                  </MenuItem>
                ))}
              <MenuItem>
                {isLoggedIn && (
                  <Link
                    onClick={() => {
                      logout();
                      handleCloseUserMenu();
                    }}
                  >
                    התנתק
                  </Link>
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
