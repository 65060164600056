import { useGetPostsByUserIdQuery } from "../../api/posts.api";
import { PostCard } from "../PostCard/PostCard";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { EmptyState } from "../../common/EmptyState";
import dog from "../../images/dog.jpg";

import { useGetUserQuery } from "../../api/user.api";
import { Loader } from "../../common/Loader";

export const MyPosts = () => {
  const { data: user } = useGetUserQuery();
  const userId = user?._id || "";
  const isLoggedIn = !isEmpty(user);
  const { data: posts, isLoading } = useGetPostsByUserIdQuery(userId, {
    skip: !isLoggedIn,
  });

  if (isLoading) {
    return <Loader />;
  }
  return !isEmpty(posts) && isLoggedIn ? (
    <Grid container spacing={2} padding="30px" justifyContent="center">
      {posts?.map((post, index) => (
        <Grid item key={index} xs="auto" width="100%">
          <PostCard key={index} post={post} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <EmptyState
      image={dog}
      title="לא נמצא פה כלום..."
      subtitle="הוסיפו פוסט חדש"
    />
  );
};
