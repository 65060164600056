import { useState, useEffect, useRef, useMemo } from "react";
import Card from "@mui/material/Card";
import {
  CardContent,
  Typography,
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Post } from "../../types/post.type";
import config from "../../config.json";
import { useGetUserQuery } from "../../api/user.api";
import { isEmpty, reduce } from "lodash";
import { useGetAllStatusesQuery } from "../../api/categories.api";

import "./PostCard.css";
import { useDeletePostMutation } from "../../api/posts.api";

export const PostCard = ({ post }: { post: Post }) => {
  const {
    location,
    description,
    images,
    title,
    phoneNumber,
    category,
    status,
    date,
    userId,
    _id,
  } = post;
  const { data: user } = useGetUserQuery();
  const [deletePost] = useDeletePostMutation();
  const { data: statuses } = useGetAllStatusesQuery();
  const user_id = user?._id || "";

  const [expandedDescription, setExpandedDescription] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEllipsized, setIsEllipsized] = useState(false);
  const textRef = useRef<HTMLElement | null>(null);

  const newDate = new Date(date);
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate =
    newDate.toLocaleString("en-IL", dateOptions) +
    " " +
    newDate.toLocaleString("en-IL", timeOptions);

  const toggleDescription = () => {
    setExpandedDescription(!expandedDescription);
  };

  let formattedPhoneNumber = "";
  if (phoneNumber) {
    const phoneNumberDigits = phoneNumber.replace(/-/g, "");
    if (phoneNumberDigits.length >= 3) {
      formattedPhoneNumber =
        phoneNumberDigits.slice(0, 3) + "-" + phoneNumberDigits.slice(3);
    } else {
      formattedPhoneNumber = phoneNumber;
    }
  }

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deletePost(_id)
      .unwrap()
      .then(() => {
        console.log("Post deleted successfully");
        setIsDialogOpen(false);
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
        setIsDialogOpen(false);
      });
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        const element = textRef.current as HTMLElement;
        setIsEllipsized(element.offsetWidth < element.scrollWidth);
      }
    };
    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);
    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, []);

  const isLoggedIn = !isEmpty(user);

  const statusTitleByName = useMemo(
    () =>
      reduce(
        statuses,
        (acc, status) => {
          acc[status.name] = status.title;

          return acc;
        },
        {} as Record<string, string>
      ),
    [statuses]
  );

  return (
    <Card
      sx={{
        direction: "rtl",
        margin: "auto",
        mb: 2,
        borderRadius: "12px",
        boxShadow: "unset",
        maxWidth: 345,
        width: "345px",
        "@media (max-width: 768px)": {
          width: "100%",
        },
      }}
    >
      {images.length > 0 ? (
        <Carousel
          showArrows={true}
          showStatus={true}
          showThumbs={false}
          dynamicHeight={false}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={`${config.s3BaseUrl}/${image}`}
                alt={title}
                style={{
                  maxHeight: "324px",
                  height: "324px",
                  objectFit: "cover",
                  maxWidth: "100%",
                  objectPosition: "center",
                  borderRadius: "12px",
                }}
                onError={(e) => {
                  const imgElement = e.target as HTMLImageElement;
                  imgElement.src = "/images/default.png";
                }}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <div>
          <img
            src="/images/default.png"
            alt="Default Image"
            style={{
              maxHeight: "324px",
              height: "324px",
              objectFit: "cover",
              width: "100%",
              objectPosition: "center",
            }}
          />
        </div>
      )}

      <CardContent>
        <Typography
          className="typography-title"
          gutterBottom
          variant="h6"
          component="div"
          sx={{ textAlign: "left" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "left",
            alignItems: "center",
            mt: 1,
            mb: 1,
          }}
        >
          <Chip
            label={statusTitleByName[status] || status}
            color="primary"
            variant="outlined"
            className="post-card-text"
          />
          <Typography
            className="post-card-text"
            color="text.primary"
            sx={{ textAlign: "left" }}
          >
            {location}{" "}
            <LocationOnIcon fontSize="small" style={{ verticalAlign: "sub" }} />
          </Typography>
        </Box>
        <Box sx={{ textAlign: "left", direction: "ltr" }}>
          {!expandedDescription && (
            <Typography
              className="post-card-text"
              ref={textRef}
              variant="h6"
              color="text.primary"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                direction: "ltr",
              }}
            >
              {description}
            </Typography>
          )}
          {expandedDescription && (
            <Typography
              className="post-card-text"
              variant="h6"
              color="text.primary"
              sx={{
                whiteSpace: "pre-wrap",
                direction: "ltr",
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
        <Typography
          variant="h6"
          className="post-card-text"
          color="text.primary"
          sx={{ textAlign: "left" }}
        >
          {formattedDate}
        </Typography>
        <Box>
          {phoneNumber && isLoggedIn ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                direction: "ltr",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography
                  className="post-card-text"
                  variant="h6"
                  sx={{ textAlign: "left" }}
                >
                  {formattedPhoneNumber}{" "}
                  <PhoneIcon
                    fontSize="small"
                    style={{ verticalAlign: "sub" }}
                  />
                </Typography>
              </Box>
              {userId === user_id && (
                <DeleteForeverIcon
                  onClick={handleDeleteClick}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
          ) : (
            <Typography
              className="post-card-text"
              variant="body1"
              color="red"
              sx={{ textAlign: "left" }}
            >
              עליך להתחבר על מנת לצפות במספר
              <PhoneIcon fontSize="small" style={{ verticalAlign: "sub" }} />
            </Typography>
          )}
          <Box sx={{ position: "relative", mt: 2 }}>
            {isEllipsized && (
              <Button
                sx={{
                  position: "absolute",
                  width: "100%",
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={toggleDescription}
              >
                {expandedDescription ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Button>
            )}
          </Box>
        </Box>
        <Dialog open={isDialogOpen} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>
              אתה בטוח שברצונך למחוק את הפוסט?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ביטול
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              אישור
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};
