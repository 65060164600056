import { useParams } from "react-router-dom";
import { useGetPostsByCategoryQuery } from "../../api/posts.api";
import { Loader } from "../../common/Loader";
import { PostCard } from "../PostCard/PostCard";
import {Grid, Pagination, Stack} from "@mui/material";
import { isEmpty } from "lodash";
import { EmptyState } from "../../common/EmptyState";
import dog from "../../images/dog.jpg";
import { useGetAllStatusesQuery } from "../../api/categories.api";
import {ChangeEvent, useState} from "react";
import {Post} from "../../types/post.type";

const limit = 20;

export const PostsList = () => {
  const [page, setPage] = useState<number>(1);

  const {category} = useParams();

  const {data: wrappedData, isLoading, isFetching} = useGetPostsByCategoryQuery({
    category: category!,
    page: page - 1,
    limit
  });

  const {data: posts, totalCount} = wrappedData || {} as any;

  const totalPages = Math.ceil(totalCount / limit);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const { isLoading: isLoadingStatuses } = useGetAllStatusesQuery();

  if (isLoading || isLoadingStatuses) {
    return <Loader/>;
  }

  return !isEmpty(posts) ? (
      <Grid container spacing={2} justifyContent="center" padding="30px">
        <Grid container spacing={2} padding="30px" justifyContent="center">
          {isFetching && <Loader/>}

          {posts?.map((post: Post, index: number) => (
            <Grid item key={index} xs="auto" width="100%">
              <PostCard key={index} post={post}/>
            </Grid>
          ))}
        </Grid>

        <Stack alignItems="center">
          <Pagination count={totalPages} page={page} onChange={handlePageChange}/>
        </Stack>
      </Grid>
    )
    :
    (
      <EmptyState image={dog}
      title="לא נמצא פה כלום..."
      subtitle="הוסיפו פוסט חדש"/>
    );
};
