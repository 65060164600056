import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import config from "../config.json";
import {AddPostRequest, City, Post} from "../types/post.type";
import {GetPostsRequest} from "../types/getPostsRequest.type";

export const postsApi = createApi({
  reducerPath: "postsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    credentials: "include",
  }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getPostsByCategory: builder.query<{ data:Post[], totalCount: number }, GetPostsRequest>({
      query: (params: GetPostsRequest) => `/posts?${new URLSearchParams(params as any).toString()}`,
      providesTags: ["Post"],
    }),
    getPostsByUserId: builder.query<Post[], string>({
      query: (userId) => `/posts/users/${userId}`,
      providesTags: ["Post"],
    }),
    deletePost: builder.mutation<void, string>({
      query: (postId: string) => ({
        url: `/posts/${postId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Post"],
    }),
    getCities: builder.query<City[], void>({
      query: () => `/posts/cities`,
    }),
    addPost: builder.mutation<Post, AddPostRequest>({
      query: (post: AddPostRequest) => {
        const formData = new FormData();

        for (const image of post.images) {
          formData.append("images", image);
        }

        for (const key of Object.keys(post)) {
          // @ts-ignore
          formData.append(key, post[key]);
        }

        return {
          url: "/posts",
          method: "POST",
          body: formData,
          headers: {
            "captcha-token": post.captchaToken,
          },
        };
      },
      invalidatesTags: ["Post"],
    }),
  }),
});

export const {
  useGetPostsByCategoryQuery,
  useGetPostsByUserIdQuery,
  useDeletePostMutation,
  useAddPostMutation,
  useGetCitiesQuery,
} = postsApi;
