import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { CustomAutocompleteProps, Option } from "../../types/common.type";
import { useGetCategoryStatusesQuery } from "../../api/categories.api";

interface StatusAutocompleteProps extends CustomAutocompleteProps {
  category: string;
}

const StatusAutocomplete = ({
  onChange,
  error,
  category,
}: StatusAutocompleteProps) => {
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const { data: statuses, isLoading } = useGetCategoryStatusesQuery(category);

  const options =
    statuses?.map((status) => ({
      value: status.name,
      label: status.title,
    })) || [];

  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option: Option) => option.label || ""}
      fullWidth
      loading={isLoading}
      value={selectedValue}
      onChange={(_, option: Option | null) => {
        setSelectedValue(option);
        onChange(option?.value as string);
      }}
      disabled={!category}
      renderInput={(params) => (
        <TextField
          margin="dense"
          error={!!error}
          helperText={error}
          {...params}
          label={!category ? "בחר קטגוריה על מנת להציג אפשרויות" : "סטטוס"}
        />
      )}
    />
  );
};

export default StatusAutocomplete;
