import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Fab,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AddIcon from "@mui/icons-material/Add";
import ImageUpload from "../../common/ImageUpload";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useGetUserQuery } from "../../api/user.api";
import { useAddPostMutation, useGetCitiesQuery } from "../../api/posts.api";
import { AddPostRequest } from "../../types/post.type";
import { ADD_POST_VALIDATION_SCHEMA } from "../../consts/validators.const";
import CategoryAutocomplete from "./CategoryAutocomplete";
import { LocationAutocomplete } from "./LocationAutocomplete";
import StatusAutocomplete from "./StatusAutocomplete";
import "./AddPost.css";
import { AddPostResult } from "./AddPostResult";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config.json";

const AddPost = () => {
  const [open, setOpen] = useState(false);

  const [captchaToken, setCaptchaToken] = useState<string | null>();

  const { data: user } = useGetUserQuery();
  const isLoggedIn = !isEmpty(user);

  const [
    addPost,
    {
      isLoading: isAddingPost,
      isSuccess: isAddedPost,
      isError: isAddedPostFailed,
      reset: resetAddPostResult,
    },
  ] = useAddPostMutation();

  useEffect(() => {
    if (isAddedPost) {
      handleClose();
    }
  }, [isAddedPost]);

  const handleClickOpen = () => {
    isLoggedIn ? setOpen(true) : (window.location.href = "/signin");
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setCaptchaToken(null);
  };

  const formik = useFormik<AddPostRequest>({
    initialValues: {
      title: "",
      location: "",
      phoneNumber: "",
      description: "",
      status: "",
      category: "",
      images: [],
    },
    validationSchema: ADD_POST_VALIDATION_SCHEMA,
    onSubmit: (values: AddPostRequest) =>
      addPost({ ...values, captchaToken: captchaToken || "" }),
  });

  const handleImageUpload = (images: File[]) => {
    formik.setFieldValue("images", images);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1300,
        }}
      >
        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </Box>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <Box
          sx={{
            margin: "0 auto",
            width: "100%",
          }}
        >
          <DialogTitle>מודעה חדשה</DialogTitle>

          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              className="add-post-dialog-content"
              sx={{
                overflowY: "auto",
                height: "calc(100vh - 160px)",
              }}
            >
              <DialogContentText>
                רשמו את מיטב הפרטים הדרושים להעלאת המודעה
              </DialogContentText>

              <TextField
                dir="rtl"
                margin="dense"
                id="title"
                label="כותרת"
                type="text"
                fullWidth
                variant="outlined"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />

              <LocationAutocomplete
                {...formik.getFieldProps("location")}
                onChange={(value: string) =>
                  formik.setFieldValue("location", value)
                }
                error={formik.touched.location ? formik.errors.location : ""}
              />

              <TextField
                fullWidth
                label="טלפון"
                variant="outlined"
                type="tel"
                {...formik.getFieldProps("phoneNumber")}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                margin="dense"
              />

              <CategoryAutocomplete
                onChange={(value: string) =>
                  formik.setFieldValue("category", value)
                }
                error={formik.touched.category ? formik.errors.category : ""}
              />

              <StatusAutocomplete
                category={formik.getFieldProps("category").value}
                onChange={(value: string) =>
                  formik.setFieldValue("status", value)
                }
                error={formik.touched.status ? formik.errors.status : ""}
              />

              <ImageUpload onFilesAdded={handleImageUpload} />

              <TextField
                margin="dense"
                id="description"
                label="תיאור"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                {...formik.getFieldProps("description")}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <ReCAPTCHA
                sitekey={config.captchaSiteKey}
                onChange={setCaptchaToken}
              />
            </DialogContent>

            <DialogActions
              sx={{
                position: "sticky",
                bottom: 0,
                background: "white",
              }}
            >
              <Button onClick={handleClose}>ביטול</Button>

              <LoadingButton
                loading={isAddingPost}
                type="submit"
                disabled={
                  !formik.isValid ||
                  !formik.dirty ||
                  isEmpty(captchaToken) ||
                  isAddingPost
                }
              >
                העלאה
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <AddPostResult
        isOpen={isAddedPostFailed || isAddedPost}
        isError={isAddedPostFailed}
        onClose={resetAddPostResult}
      />
    </Box>
  );
};

export default AddPost;
