import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

const AUTO_HIDE_DURATION = 4000;

interface AddPostResultProps {
  isOpen: boolean;
  onClose: () => void;
  isError: boolean;
}

export const AddPostResult = ({
  isOpen,
  onClose,
  isError,
}: AddPostResultProps) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={isError ? "error" : "success"}>
        {isError
          ? "ההוספה נכשלה אנא נסה שנית או פנה למנהל המערכת"
          : "הפוסט נוסף בהצלחה!"}
      </Alert>
    </Snackbar>
  );
};
