import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../Routes";
import AddPost from "../Posts/Pages/AddPost";
import ResponsiveAppBar from "./Header/ResponsiveAppBar";

export const Home = () => (
  <>
    <BrowserRouter>
      <ResponsiveAppBar />
      <AddPost />
      <AppRoutes />
    </BrowserRouter>
  </>
);
