import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useGetCategoriesQuery } from "../../api/categories.api";
import { useNavigate } from "react-router-dom";

export const Categories = () => {
  const navigate = useNavigate();
  const { data: categories, isLoading } = useGetCategoriesQuery();

  const handleCategoryClick = (categoryName: string) => {
    navigate(`posts/${categoryName}`);
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={2} padding="30px" justifyContent="center">
      {categories?.map((category) => (
        <Grid item key={category.name} xs="auto" width="100%">
          <Card onClick={() => handleCategoryClick(category.name)}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={`/images/categories/${category.name}.jpg`}
                alt={category.name}
              />

              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {category.title}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  {category.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
