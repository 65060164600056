import { Autocomplete, TextField } from "@mui/material";
import { useGetCitiesQuery } from "../../api/posts.api";
import { CustomAutocompleteProps } from "../../types/common.type";

export const LocationAutocomplete = ({
  onChange,
  value,
  error,
}: CustomAutocompleteProps) => {
  const { data: cities, isLoading } = useGetCitiesQuery();

  const options = cities?.map((city) => city.name) || [];

  return (
    <Autocomplete
      disablePortal
      options={options}
      fullWidth
      loading={isLoading}
      value={value}
      onChange={(_, value: string | null) => onChange(value!)}
      renderInput={(params) => (
        <TextField
          margin="dense"
          error={!!error}
          helperText={error}
          {...params}
          label="מיקום"
        />
      )}
    />
  );
};
