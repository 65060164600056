import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import Copyright from "../../Footer/Copyright";
import "./Signin.css";
import config from "../../config.json";
import { useGetUserQuery } from "../../api/user.api";
import { isEmpty } from "lodash";

export default function SignIn() {
  const { data: user } = useGetUserQuery();

  const handleGoogleLoginClick = () => {
    window.location.href = `${config.apiBaseUrl}/auth`;
  };

  const isLoggedIn = !isEmpty(user);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "90vh" }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              התחברות
            </Typography>

            <button
              type="button"
              className="login-with-google-btn"
              onClick={handleGoogleLoginClick}
            >
              התחברות עם גוגל
            </button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
