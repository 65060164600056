import React, { useCallback, useState, MouseEvent } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const DropzoneArea = styled("div")({
  padding: "16px",
  textAlign: "center",
  border: "2px dashed gray",
  cursor: "pointer",
  marginBottom: "10px",
});

const ThumbnailsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "10px",
  marginTop: "10px",
});

const Thumbnail = styled("img")({
  width: "100px",
  height: "100px",
  objectFit: "cover",
  border: "1px solid #ddd",
  borderRadius: "4px",
});

const ThumbnailContainer = styled("div")({
  position: "relative",
  width: "100px",
  height: "100px",
});

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "transparent",
  color: theme.palette.common.white,
  width: "40px",
  height: "40px",
  "& svg": {
    fontSize: "2rem",
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));

interface ImageUploadProps {
  onFilesAdded?: (files: File[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onFilesAdded }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [hoveredFile, setHoveredFile] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.slice(0, 4 - files.length);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      if (onFilesAdded) {
        onFilesAdded(newFiles);
      }
    },
    [files, onFilesAdded]
  );

  const removeFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*" as any,
    maxFiles: 4 - files.length,
  });

  return (
    <div>
      <DropzoneArea {...getRootProps()}>
        <input
          {...getInputProps()}
          onClick={(event: MouseEvent<HTMLInputElement>) => {
            if (files.length >= 4) {
              event.preventDefault();
            }
          }}
        />
        <Typography variant="body2">
          גרור תמונות לכאן, או לחץ כדי לבחור תמונות
        </Typography>
      </DropzoneArea>
      <aside>
        <ThumbnailsContainer>
          {files.map((file) => (
            <ThumbnailContainer
              key={file.name}
              onMouseEnter={() => setHoveredFile(file.name)}
              onMouseLeave={() => setHoveredFile(null)}
            >
              <Thumbnail src={URL.createObjectURL(file)} alt={file.name} />
              {hoveredFile === file.name && (
                <DeleteButton onClick={() => removeFile(file)} size="small">
                  <DeleteIcon />
                </DeleteButton>
              )}
            </ThumbnailContainer>
          ))}
        </ThumbnailsContainer>
        {files.length > 0 && (
          <Button
            onClick={() => {
              setFiles([]);
            }}
            variant="outlined"
            color="secondary"
            style={{ marginTop: "10px" }}
          >
            מחק הכל
          </Button>
        )}
      </aside>
    </div>
  );
};

export default ImageUpload;
