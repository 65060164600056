import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../types/user.type";
import config from "../config.json";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    credentials: "include",
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => '/auth/user',
      providesTags: ['User']
    }),
    logout: builder.mutation<string, void>({ query: () => '/auth/logout', invalidatesTags: ['User'] })
  }),
});

export const { useGetUserQuery, useLogoutMutation } = userApi;
