import { Autocomplete, TextField } from "@mui/material";
import { useGetCategoriesQuery } from "../../api/categories.api";
import { useState } from "react";
import { CustomAutocompleteProps, Option } from "../../types/common.type";

const CategoryAutocomplete = ({ onChange, error }: CustomAutocompleteProps) => {
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const { data: categories, isLoading } = useGetCategoriesQuery();

  const options =
    categories?.map((category) => ({
      value: category.name,
      label: category.title,
    })) || [];

  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option: Option) => option.label || ""}
      fullWidth
      loading={isLoading}
      value={selectedValue}
      onChange={(_, option: Option | null) => {
        setSelectedValue(option);
        onChange(option?.value as string);
      }}
      renderInput={(params) => (
        <TextField
          margin="dense"
          error={!!error}
          helperText={error}
          {...params}
          label="קטגוריות"
        />
      )}
    />
  );
};

export default CategoryAutocomplete;
