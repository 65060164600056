import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config.json";
import { Category, Status } from "../types/category.type";

export const categoriesApi = createApi({
    reducerPath: "categoriesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiBaseUrl,
        credentials: "include",
    }),
    endpoints: (builder) => ({
        getCategories: builder.query<Category[], void>({
            query: () => `/categories`,
        }),
        getAllStatuses: builder.query<Status[], void>({
            query: () => `/categories/statuses`,
        }),
        getCategoryStatuses: builder.query<Status[], string>({
            query: (category: string) => `/categories/${category}/statuses`,
        }),
    }),
});

export const { useGetCategoriesQuery, useGetAllStatusesQuery, useGetCategoryStatusesQuery } = categoriesApi;
