import { Route, Routes } from "react-router-dom";
import { PostsList } from "./Posts/PostsList/PostsList";
import { CategorySelection } from "./CategorySelection/CategorySelection";
import SignIn from "./Users/pages/SignIn";
import { MyPosts } from "./Posts/PostsList/MyPosts";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CategorySelection />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/posts/:category" element={<PostsList />} />
      <Route path="/my-posts" element={<MyPosts />} />
    </Routes>
  );
}

export default AppRoutes;
