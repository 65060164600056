import { Box, Typography } from "@mui/material";
import "./CategorySelection.css";
import { Categories } from "./Categories/Categories";

export const CategorySelection = () => (
  <Box sx={{ padding: "30px 0" }}>
    <Typography className="category-selection-heading" variant="body2">
      איזה פוסטים תרצה לראות?
    </Typography>

    <Categories />
  </Box>
);
